import { getMesh, getTexture } from "@/data/assetLoader/asset.store";
import { Interactable } from "@game/input/interactable";
import { CCrop } from "@game/sim/components/CCrop";
import type { TRendererProps } from "@game/sim/components/CRenderer";
import useEntityUpdate from "@game/sim/useEntityUpdate";
import { Sparkles } from "@react-three/drei";
import { useMemo, useState } from "react";
import { DoubleSide, type Mesh, Vector3 } from "three";

const meshNames = ["farmplants/farmplants/nwheat000", "farmplants/farmplants/nwheat001", "farmplants/farmplants/nwheat002", "farmplants/farmplants/nwheat003"];

export const ItemWheatRenderer = ({
	entity,
	props: { pos, rot },
}: TRendererProps) => {
	const [growthPercent, setGrowthPercent] = useState(0);

	useEntityUpdate(() => {
		const crop = entity.component(CCrop);
		if (crop.growthPercent !== growthPercent) {
			setGrowthPercent(crop.growthPercent);
		}
	}, entity);

	const meshes = useMemo(() => {
		const meshes: Mesh[] = [];
		for (let i = 0; i < meshNames.length; i++) {
			meshes.push(getMesh(meshNames[i]));
		}
		return meshes;
	}, []);

	const { wheat, tex } = useMemo(() => {
		const wheatMesh =
			meshes[Math.max(0, Math.floor(growthPercent * (meshes.length - 1)))];
		const tex = getTexture("farmplants/wheat/wheat");
		if (!wheatMesh || !tex) return {};
		return {
			wheat: wheatMesh,
			tex,
		};
	}, [growthPercent, meshes]);

	const scale = 6;
	const grown = growthPercent >= 0.95;

	const renderer = useMemo(() => {
		if (!wheat) return null;
		return (
			<group position={new Vector3(...pos)}>
				<Interactable
					entity={entity}
					position={[0, 0.125, 0]}
					args={[0.15, 0.15, 0.15]}
				/>
				<mesh
					scale={[scale, scale, scale]}
					rotation={[0, rot, 0]}
					geometry={wheat.geometry}
				>
					<meshBasicMaterial
						map={tex}
						transparent
						side={DoubleSide}
						color={"#F8B33E"}
						alphaTest={0.1}
						// alphaMap={tex}
					/>
					{grown && (
						<Sparkles
							size={0.75}
							speed={0.2}
							count={5}
							noise={50}
							color={"yellow"}
						/>
					)}
				</mesh>
			</group>
		);
	}, [pos, rot, grown, wheat, entity, tex]);
	return <>{renderer}</>;
};
