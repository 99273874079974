import mitt from "mitt";
import type { SimEntity } from "./SimEntity";
import type { TTask } from "./task";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type TSimEvent = {
	loadingComplete: { lfg: boolean };
	blockUpdate: undefined;
	startGame: undefined;
	islandCreated: undefined;
	loading: undefined;
	sendSound: {value: string};
};

export type IWorldGlobals = {
	fertility_multiplier: number;
	blurredVision: boolean;
	pinkVision: boolean;
	stormTimer: number;
};

const useWorldGlobals = create<IWorldGlobals>()(
	immer((_set, _get) => ({
		fertility_multiplier: 0,
		blurredVision: false,
		pinkVision: false,
		stormTimer: -1,
	})),
);

const WorldGlobals = () => ({
	...useWorldGlobals.getState(),
	set: useWorldGlobals.setState,
});

export { useWorldGlobals, WorldGlobals };

export const simEvents = mitt<TSimEvent>();

type TTheSim = {
	latestBlock: number;
	latestTimestamp: number;
	worldGlobal: () => IWorldGlobals;
} & ReturnType<typeof mitt<TSimEvent>>;

const theSim: TTheSim = {
	latestBlock: 0,
	latestTimestamp: 0,
	worldGlobal: WorldGlobals,
	...simEvents,
};

const entityRefs = new Map<string, SimEntity>();
const addEntity = (entity: SimEntity) => {
	if (entityRefs.has(entity.ref)) {
		throw new Error("[World] Entity already exists");
	}
	entityRefs.set(entity.ref, entity);
};
const getEntityByRef = (ref: string | undefined) => {
	if (!ref) return undefined;
	return entityRefs.get(ref);
};
const removeEntity = (entity: SimEntity) => {
	if (entityRefs.has(entity.ref)) {
		entityRefs.delete(entity.ref);
	}
};

const removeEntityByRef = (ref: string) => {
	const entity = getEntityByRef(ref);
	if (entity) {
		removeEntity(entity);
	}
};

const taskRefs = new Map<string, TTask>();
const addTask = (task: TTask) => {
	if (taskRefs.has(task.ref)) {
		throw new Error("[World] Task already exists");
	}
	taskRefs.set(task.ref, task);
};
const getTaskByRef = (ref: string | undefined) => {
	if (!ref) return undefined;
	return taskRefs.get(ref);
};
const removeTask = (ref: string) => {
	if (taskRefs.has(ref)) {
		taskRefs.delete(ref);
	}
};

export {
	theSim,
	addEntity,
	getEntityByRef,
	removeEntity,
	removeEntityByRef,
	entityRefs,
	addTask,
	getTaskByRef,
	removeTask,
	taskRefs,
};
