import { logVerbose } from "@/data/dev.store";
import { getSigilByIcon } from "@/data/sigil.data";
import { Debug } from "@/lib/debug/debug";
import { ENV } from "@/lib/env";
import { useInventoryStore } from "@game/ui/components/inventory/inventory.store";
import usePartySocket from "partysocket/react";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect } from "react";
import type { Vector3 } from "three";
import { generateUUID } from "three/src/math/MathUtils.js";
import { SigilStore } from "./sigils.store";
import type { TSigil } from "./sigils.types";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { theSim } from "@game/sim/sim.store";
import Audio, { type TAudioBank } from "@game/audio/audioPlayer";

interface SigilSystemContext {
	startSigil: (position: Vector3, duration: number) => void;
	finishSigil: () => void;
}

export const CursorsContext = createContext<SigilSystemContext>(null!);

export const SigilSystemProvider = ({ children }: { children: ReactNode }) => {
	const { activeItem } = useInventoryStore((state) => {
		return {
			activeItem: state.getActiveItem(),
		};
	});
	const socket = usePartySocket({
		host: ENV.VITE_SIGILS_SERVER,
		room: "my-room",
	});

	const startSigil = async (position: Vector3, duration: number) => {
		if (!activeItem) return;
		logVerbose() && Debug("Sigils").log("startSigil", position);
		if (SigilStore().playerSigil) {
			finishSigil();
		}
		const icon = getSigilByIcon(
			activeItem?.component(CInventoryItem)?.sigil || "",
		);
		console.log(
			"SIGILICON",
			icon,
			activeItem?.component(CInventoryItem)?.sigil,
		);
		const sigil = {
			id: generateUUID(),
			x: position.x,
			y: position.y,
			z: position.z,
			sigilID: -1,
			lastUpdate: Date.now() - 100,
			duration: -1,
			sigilIcon: icon?.[1],
		} as TSigil;
		SigilStore().set({ playerSigil: sigil });
		const msg = JSON.stringify({ type: "start", sigil });
		logVerbose() && Debug("Sigils").log("send", msg);
		socket.send(msg);
	};

	const finishSigil = () => {
		const activeSigil = SigilStore().playerSigil;
		if (activeSigil === null) return;
		const sigil = {
			...activeSigil,
			duration: Date.now() - activeSigil.lastUpdate,
		};
		SigilStore().set({ playerSigil: null });
		SigilStore().addSigil(sigil);
		const msg = JSON.stringify({ type: "end", sigil });
		logVerbose() && Debug("Sigils").log("send", msg);
		socket.send(msg);
	};

	useEffect(() => {
		if (!socket) return;
		const sendSocketSound = async ({value}: {value: string}) => {
			const msg = JSON.stringify({ type: "sound", sigil: {sound:value} });
			logVerbose() && Debug("Sigils").log("send", msg);
			socket.send(msg);
			// console.log("event", value, msg);
		};
		theSim.on("sendSound", sendSocketSound);
		return () => {
			theSim.off("sendSound", sendSocketSound);
		};
	}, [socket])

	useEffect(() => {
		if (socket) {
			const onMessage = async (evt: WebSocketEventMap["message"]) => {
				const msg = JSON.parse(evt.data as string) as {
					type: string;
					sigil: TSigil;
				};

				// console.log(msg.type,msg);
				switch (msg.type) {
					case "sound":
						const {sound} = msg.sigil as unknown as {sound: string};
						if (sound) {
							let audioPath = sound.split("?")[0];
							let args = [
								audioPath,
								JSON.parse(sound.split("?")[1] || "{}"),
							]
							// console.log(args);
							Audio.playSound(args[0] as TAudioBank, args[1] || undefined);
							return;
						}
						break;
					case "start":
						SigilStore().addSigil(msg.sigil);
						break;
					case "end":
						SigilStore().updateSigil(msg.sigil);
						break;
				}
			};
			socket.addEventListener("message", onMessage);

			return () => {
				// @ts-ignore
				socket.removeEventListener("message", onMessage);
			};
		}
	}, [socket]);

	return (
		<CursorsContext.Provider value={{ startSigil, finishSigil }}>
			{children}
		</CursorsContext.Provider>
	);
};

export const useSigilSystem = () => {
	const context = useContext(CursorsContext);
	if (!context) {
		throw new Error("useSigilSystem must be used within a SigilSystemProvider");
	}
	return context;
};
