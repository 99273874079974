import { type AUDIO_DATA, nextSound, playIndex } from "@/data/audio.data";
import { Howl } from "howler";

export type TAudioBank = keyof typeof AUDIO_DATA;

const activeMusic = {
	music: null as Howl | null,
};

const playSound = (
	audiobank: TAudioBank,
	opts: { volume?: number; index?: number } = {},
) => {
	const options = Object.assign({ volume: 0.5 }, opts);
	const sound_file =
		opts.index !== undefined
			? playIndex(audiobank, opts.index)
			: nextSound(audiobank);
	const sound = new Howl({
		src: [sound_file],
		autoplay: true,
		volume: options.volume,
	});
	sound.play();
};

const playMusic = (
	audiobank: TAudioBank,
	opts: { volume?: number } = {},
) => {
	const options = Object.assign({ volume: 0.5 }, opts);
	stopMusic();
	const sound_file = nextSound(audiobank);
	const sound = new Howl({
		src: [sound_file],
		autoplay: true,
		volume: options.volume,
	});
	activeMusic.music = sound;
	activeMusic.music.play();
};

const stopMusic = () => {
	if (activeMusic.music) {
		const m = activeMusic.music;
		m.fade(activeMusic.music.volume(), 0, 3);
		setTimeout(() => {
			m.stop();
		}, 3250);
		activeMusic.music = null;
	}
};

const Audio = {
	playSound,
	playMusic,
	stopMusic,
};

export default Audio;
